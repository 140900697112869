import Vue from 'vue';
import 'primeicons/primeicons.css';
import 'swiper/swiper-bundle.min.css';

import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Swiper from 'swiper/swiper-bundle.min';

Vue.prototype.$swiper = Swiper;

Vue.use(PrimeVue);

Vue.component('Textarea', Textarea);
Vue.component('InputText', InputText);
Vue.component('Dropdown', Dropdown);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Button', Button);
Vue.component('Checkbox', Checkbox);
