export default ({ app, i18n }, inject) => {
    inject('clone', (item) => {
        return JSON.parse(JSON.stringify(item));
    });
    inject('scrollToElement', (selector, offset = 150, mode = 'smooth') => {
        const targetScrollY = document.querySelector(selector).getBoundingClientRect().top + window.scrollY - offset;

        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ left: 0, top: targetScrollY, behavior: mode });
        } else {
            window.scrollTo(0, targetScrollY);
        }
    });
    inject('getOgTitle', (title) => {
        return `${title} - Bányai & Partners Consulting`;
    });
    inject('getIsLangKoZhDe', () => {
        // On these languages we have to hide all job-seeker related pages
        return /(ko|zh|de)/.test(i18n.locale);
    });
};
