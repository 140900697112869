export default ({ $config, $device }, inject) => {
    // Resources for consent mode
    // https://www.youtube.com/watch?v=MqAEbshMv84
    // https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#gtag.js_2
    // https://developers.google.com/tag-platform/security/guides/consent-debugging

    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    const savedConsent = JSON.parse(localStorage.getItem('consent'));

    gtag('consent', 'default', {
        security_storage: 'granted',
        functionality_storage: 'granted',
        analytics_storage: savedConsent?.statistics ? 'granted' : 'denied',
        personalization_storage: savedConsent?.preferences ? 'granted' : 'denied',
        ad_storage: savedConsent?.marketing ? 'granted' : 'denied',
        ad_user_data: savedConsent?.marketing ? 'granted' : 'denied',
        ad_personalization: savedConsent?.marketing ? 'granted' : 'denied',
    });

    inject('gtag', gtag);

    if ($config.nodeENV !== 'production' || $device.isCrawler || /melkweg/.test(window.location.origin)) {
        return;
    }

    /* eslint-disable */
    // Google Tag Manager
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', $config.gtmID);
    /* eslint-enable */
};
