<template>
    <header class="fixed top-0 left-0 justify-between items-center w-full">
        {{/* DESKTOP, Primary menu */}}
        <div
            v-if="$device.isDesktop"
            class="hidden xl:block rounded-br-40 transition-shadow"
            :class="{ 'shadow-lg': !isNavigationTransparent }"
        >
            <div
                class="absolute top-0 left-0 w-full h-full transition-opacity purple-gradient-bg rounded-br-40 z-0"
                :class="!isNavigationTransparent ? 'opacity-100' : 'opacity-0'"
            ></div>
            <div class="container flex justify-between relative z-10">
                <div class="flex">
                    <NuxtLink
                        class="w-10 mr-5 h-full bg-contain bg-center bg-no-repeat"
                        :class="{ 'absolute top-0 -left-6 -translate-x-full ': false }"
                        :to="layout === 'employer' ? localePath('/') : localePath('/job-seeker')"
                        :style="`background-image: url('${require('/assets/images/logos/logo.svg')}')`"
                    >
                    </NuxtLink>

                    <div
                        class="animated-underline-wrapper relative -ml-4"
                        :class="{ 'animated-underline-wrapper--active': isEmployerLinkActive }"
                        @mouseleave="$device.isMobileOrTablet ? null : (isEmployerNavPanelOpen = false)"
                        @mouseenter="$device.isMobileOrTablet ? null : (isEmployerNavPanelOpen = true)"
                    >
                        <NuxtLink class="nav-item" :to="localePath('/')">
                            <span class="font-bold animated-underline mr-2">{{ $t('navigation.companies') }}</span>
                            <i
                                class="pi pi-chevron-down text-sm transition-transform"
                                :class="{ '-rotate-180': isEmployerNavPanelOpen }"
                            ></i>
                        </NuxtLink>
                        <div
                            class="desktop-nav-dropdown__panel w-full xl:w-[calc(100%+7rem)]"
                            :class="{ active: isEmployerNavPanelOpen }"
                        >
                            <NuxtLink
                                v-for="(link, i) in links['employer']"
                                :key="`desktop-employer-link-${i}`"
                                :active-class="'bg-gray-150'"
                                :to="localePath(link.path)"
                            >
                                {{ link.label }}
                            </NuxtLink>
                        </div>
                    </div>

                    <div
                        v-if="!$getIsLangKoZhDe()"
                        class="animated-underline-wrapper relative"
                        :class="{ 'animated-underline-wrapper--active': layout === 'employee' }"
                        @mouseleave="$device.isMobileOrTablet ? null : (isEmployeeNavPanelOpen = false)"
                        @mouseenter="$device.isMobileOrTablet ? null : (isEmployeeNavPanelOpen = true)"
                    >
                        <NuxtLink class="nav-item" :to="localePath('/job-seeker')">
                            <span class="font-bold animated-underline mr-2">{{ $t('navigation.job_seekers') }}</span>
                            <i
                                class="pi pi-chevron-down text-sm transition-transform"
                                :class="{ '-rotate-180': isEmployeeNavPanelOpen }"
                            ></i>
                        </NuxtLink>
                        <div
                            class="desktop-nav-dropdown__panel w-full xl:w-[calc(100%+6rem)]"
                            :class="{ active: isEmployeeNavPanelOpen }"
                        >
                            <NuxtLink
                                v-for="(link, i) in links['employee']"
                                :key="`desktop-employee-link-${i}`"
                                :active-class="'bg-gray-150'"
                                :to="localePath(link.path)"
                            >
                                {{ link.label }}
                            </NuxtLink>
                        </div>
                    </div>

                    <div
                        class="animated-underline-wrapper relative"
                        :class="{ 'animated-underline-wrapper--active': getRouteBaseName() === 'about-us' }"
                    >
                        <NuxtLink class="nav-item" :to="localePath('/about-us')">
                            <span class="font-bold animated-underline">{{ $t('about_us.title') }}</span>
                        </NuxtLink>
                    </div>

                    <div
                        v-if="!$getIsLangKoZhDe()"
                        class="animated-underline-wrapper relative"
                        :class="{ 'animated-underline-wrapper--active': getRouteBaseName() === 'news' }"
                    >
                        <NuxtLink class="nav-item" :to="localePath('/news')">
                            <span class="font-bold animated-underline">{{ $t('news.title') }}</span>
                        </NuxtLink>
                    </div>
                    <div
                        class="animated-underline-wrapper relative"
                        :class="{ 'animated-underline-wrapper--active': getRouteBaseName() === 'contact' }"
                    >
                        <NuxtLink class="nav-item" :to="localePath('/contact')">
                            <span class="font-bold animated-underline">{{ $t('contact.title') }}</span>
                        </NuxtLink>
                    </div>
                </div>

                <div class="flex items-center">
                    <ClientOnly v-if="!$getIsLangKoZhDe()">
                        <JobOffersNotificationButton v-if="$device.isDesktop && $store.state.windowWidth >= 1280" />
                    </ClientOnly>

                    <div
                        class="relative cursor-pointer h-full"
                        @mouseleave="$device.isMobileOrTablet ? null : (isLanguageDropdownOpen = false)"
                        @mouseenter="$device.isMobileOrTablet ? null : (isLanguageDropdownOpen = true)"
                    >
                        <div class="inline-flex items-center justify-between min-w-[160px] h-full px-4">
                            <div class="inline-flex items-center">
                                <span
                                    class="w-5 h-5 bg-center bg-no-repeat bg-contain mr-2"
                                    :style="`background-image: url(${require('~/assets/images/icons/globe.svg')})`"
                                ></span>
                                <span>{{ currentSelectedLocale.name }}</span>
                            </div>
                            <i
                                class="pi pi-chevron-down transition-transform"
                                :class="{ '-rotate-180': isLanguageDropdownOpen }"
                            ></i>
                        </div>

                        <div class="desktop-nav-dropdown__panel w-full" :class="{ active: isLanguageDropdownOpen }">
                            <button
                                v-for="locale in $i18n.locales"
                                :key="locale.code"
                                class="block"
                                :class="{ 'bg-gray-150': currentSelectedLocale.code === locale.code }"
                                @click="setLocale(locale.code)"
                            >
                                {{ locale.name }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {{/* MOBILE menu */}}
        <div class="h-18 purple-gradient-bg xl:rounded-br-40" :class="{ 'xl:hidden': $device.isDesktop }">
            <div
                class="fixed top-0 left-0 w-full h-full bg-black/70 transition-opacity"
                :class="isMobileMenuOpen ? 'opacity-1 pointer-events-all' : 'opacity-0 pointer-events-none'"
                @click="closeMobileMenu"
            ></div>
            <div class="container flex items-center justify-between h-full">
                <NuxtLink
                    class="mobile-company-logo fixed top-6 left-4 z-20"
                    :to="layout === 'employer' ? localePath('/') : localePath('/job-seeker')"
                >
                    <SiteLogo class="w-9 h-9" :monochrome="isMobileMenuOpen" />
                </NuxtLink>
                <div class="flex items-center ml-auto pr-[2.15rem]">
                    <div class="flex items-center xl:hidden">
                        <span
                            class="w-4 h-4 bg-contain bg-no-repeat bg-center mr-1"
                            :style="`background-image: url(${require('~/assets/images/icons/globe.svg')})`"
                        ></span>
                        <select
                            :value="currentSelectedLocale.code"
                            name=""
                            class="bg-transparent focus:outline-none"
                            @change="setLocale"
                        >
                            <option
                                v-for="locale in $i18n.locales"
                                :key="locale.code"
                                :value="locale.code"
                                class="text-black"
                            >
                                {{ locale.name }}
                            </option>
                        </select>
                    </div>
                    <button
                        class="hamburger-button fixed -mb-1 right-4 z-20"
                        :class="{ open: isMobileMenuOpen }"
                        @click="toggleMobileMenu"
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>

            <div
                class="fixed top-0 right-0 w-full xl:w-5/12 h-screen purple-gradient-bg"
                :class="{ 'translate-x-full': !isMobileMenuOpen }"
                style="transition: transform 0.3s ease"
            >
                <div
                    class="absolute w-full overflow-hidden bottom-0 left-0 xl:px-5 z-20"
                    style="height: calc(100% - 5rem)"
                >
                    <div ref="mobileMenuScrollContainer" class="container overflow-y-auto h-full">
                        <div class="pt-2 pb-24 z-20">
                            <div class="flex flex-col items-start text-sm">
                                <NuxtLink
                                    class="w-full py-3 md:text-xl font-bold"
                                    :to="localePath('/')"
                                    :class="{ underline: getRouteBaseName() === 'index' }"
                                >
                                    {{ $t('navigation.companies') }}
                                </NuxtLink>

                                <NuxtLink
                                    v-for="(link, i) in links['employer']"
                                    :key="`mobile-employer-link-${i}`"
                                    class="w-full md:text-lg py-3"
                                    active-class="underline"
                                    :to="localePath(link.path)"
                                >
                                    {{ link.label }}
                                </NuxtLink>

                                <NuxtLink
                                    v-if="!$getIsLangKoZhDe()"
                                    class="w-full pt-5 pb-3 mt-2 md:text-xl font-bold border-t border-white/20"
                                    :to="localePath('/job-seeker')"
                                    :class="{ underline: getRouteBaseName() === 'job-seeker' }"
                                >
                                    {{ $t('navigation.job_seekers') }}
                                </NuxtLink>

                                <NuxtLink
                                    v-for="(link, i) in links['employee']"
                                    :key="`mobile-employee-link-${i}`"
                                    class="w-full md:text-lg py-3"
                                    active-class="underline"
                                    :to="localePath(link.path)"
                                >
                                    {{ link.label }}
                                </NuxtLink>

                                <NuxtLink
                                    class="w-full pt-5 pb-3 md:text-xl mt-2 font-bold border-t border-white/20"
                                    :to="localePath('/about-us')"
                                    active-class="underline"
                                >
                                    {{ $t('about_us.title') }}
                                </NuxtLink>

                                <NuxtLink
                                    class="w-full pt-5 pb-3 md:text-xl mt-2 font-bold border-t border-white/20"
                                    :to="localePath('/contact')"
                                    active-class="underline"
                                >
                                    {{ $t('contact.title') }}
                                </NuxtLink>

                                <NuxtLink
                                    v-if="!$getIsLangKoZhDe()"
                                    class="w-full pt-5 pb-3 md:text-xl mt-2 font-bold border-t border-white/20"
                                    :to="localePath('/news')"
                                    active-class="underline"
                                >
                                    {{ $t('news.title') }}
                                </NuxtLink>

                                <ClientOnly>
                                    <JobOffersNotificationButton
                                        v-if="
                                            ($device.isMobileOrTablet || $store.state.windowWidth < 1280) &&
                                            !$getIsLangKoZhDe()
                                        "
                                        class="mt-5"
                                    />
                                </ClientOnly>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="absolute bottom-2 right-2 w-20 h-10 bg-contain bg-no-repeat bg-right-top z-30"
                    :style="`background-image: url(${require('~/assets/images/illustrations/yellow-dots.svg')})`"
                ></div>
            </div>
        </div>
    </header>
</template>

<script>
import { throttle } from 'throttle-debounce';
import SiteLogo from '@/components/Generic/SiteLogo';
import JobOffersNotificationButton from '@/components/Generic/JobOffersNotificationButton';

export default {
    name: 'Header',
    components: {
        SiteLogo,
        JobOffersNotificationButton,
    },
    data() {
        return {
            isLanguageDropdownOpen: false,
            isMobileMenuOpen: false,
            prevWindowWidth: null,

            isEmployerNavPanelOpen: false,
            isEmployeeNavPanelOpen: false,

            resizeHandler: throttle(100, this.handleResize),
        };
    },
    computed: {
        currentSelectedLocale() {
            return this.$i18n.locales.filter((i) => i.code === this.$i18n.locale)[0];
        },
        links() {
            const employer = [
                { path: '/indian-workforce', label: this.$t('indian_workforce.title') },
                { path: '/filipino-workforce', label: this.$t('filipino_workforce.title') },
                { path: '/services', label: this.$t('solutions.title') },
                { path: '/case-studies', label: this.$t('case_studies.title') },
            ];

            const employee = [];

            if (!this.$getIsLangKoZhDe()) {
                employee.push({ path: '/job-seeker/jobs', label: this.$t('jobs.title') });
                employee.push({ path: '/job-seeker/advice', label: this.$t('advice.title') });
            }

            return {
                employer,
                employee,
            };
        },
        activeLink() {
            return this.links[this.layout].find((link) => {
                return this.$route.path.match(link.path);
            });
        },
        isEmployerLinkActive() {
            if (!this.getRouteBaseName()) {
                return null;
            }
            return !!this.getRouteBaseName().match(
                /^(index|services|case-studies|indian-workforce|filipino-workforce)$/
            );
        },
        layout() {
            // basically this is for the case when we get 404 and thus we don't have this.getRouteBaseName().
            if (!this.getRouteBaseName()) {
                if (this.$route.path.match(/^(\/[a-z]{2})?\/job-seeker/)) {
                    return 'employee';
                }
                return 'employer';
            }

            // this is for every other case
            return this.getRouteBaseName().match(/^job-seeker/) ? 'employee' : 'employer';
        },
        isNavigationTransparent() {
            if (!this.getRouteBaseName()) {
                return;
            }

            return (
                !this.getRouteBaseName().match(
                    /^(news-article|news-preview-article|job-seeker-advice-advice|job-seeker-advice-preview-advice_preview|cookie-policy|privacy-policy)$/
                ) && this.$store.state.globals.scrollY < 50
            );
        },
    },
    watch: {
        isMobileMenuOpen() {
            if (this.isMobileMenuOpen) {
                this.$disableBodyScroll(this.$refs.mobileMenuScrollContainer);
            } else {
                this.$enableBodyScroll(this.$refs.mobileMenuScrollContainer);
            }
        },
        '$route.path'() {
            this.closeMobileMenu();
            this.isEmployerNavPanelOpen = false;
            this.isEmployeeNavPanelOpen = false;
            this.isLanguageDropdownOpen = false;
        },
    },
    mounted() {
        window.addEventListener('resize', this.resizeHandler);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
        this.$enableBodyScroll(this.$refs.mobileMenuScrollContainer);
    },
    methods: {
        setLocale(localeCode) {
            let lngCode;

            if (localeCode.target) {
                lngCode = localeCode.target.value;
            } else {
                lngCode = localeCode;
            }

            setTimeout(() => {
                const { alternateUrlsForCurrentPage } = this.$store.state.globals;
                const urlToRedirectTo = alternateUrlsForCurrentPage.find((x) => x.lang_id === lngCode);
                const fallbackUrlToRedirectTo = alternateUrlsForCurrentPage.find((x) => x.key === 'fallback');

                if (!alternateUrlsForCurrentPage.length) {
                    this.$i18n.setLocale(lngCode);
                    return;
                }

                if (urlToRedirectTo) {
                    this.$router.push({ path: this.localePath(urlToRedirectTo.path, lngCode) });
                } else {
                    this.$router.push({ path: this.localePath(fallbackUrlToRedirectTo.path, lngCode) });
                }
            }, 50);
        },
        openMobileMenu() {
            this.isMobileMenuOpen = true;
        },
        closeMobileMenu() {
            this.isMobileMenuOpen = false;
        },
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
        handleResize() {
            if (window.innerWidth >= 768 && this.prevWindowWidth < 768) {
                this.isMobileMenuOpen = false;
                this.$nextTick(() => {
                    this.$enableBodyScroll(this.$refs.mobileMenuScrollContainer);
                });
            } else if (window.innerWidth < 768 && this.prevWindowWidth > 768) {
                this.closeMobileMenu();
            }
            this.prevWindowWidth = window.innerWidth;
        },
    },
};
</script>

<style scoped>
.desktop-nav-dropdown__panel {
    opacity: 0;
    pointer-events: none;
    transform: translateY(calc(100% - 30px));
    transition: transform 0.2s, opacity 0.15s;
    @apply absolute p-2 bottom-0 bg-white rounded-xl text-black overflow-hidden shadow-lg;
    a,
    button {
        @apply block w-full text-left px-2 py-2 rounded-lg mb-1 transition-colors desktop:hover:bg-gray-150;
    }
    &.active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(calc(100% - 24px));
    }
}

.nav-item {
    @apply inline-flex items-center px-5 py-10;
}

.flag-icon {
    width: 1rem;
    height: 0.75rem;
    background-size: cover;
    background-position: center center;
    margin-left: 0.4rem;
    transform: translateY(0.1rem);
}

.hamburger-button {
    span {
        display: block;
        width: 24px;
        height: 3px;
        margin-bottom: 4px;
        background-color: #fff;
        border-radius: 5px;
        transition-timing-function: linear;
        transition-duration: 0.2s;
    }

    &.open {
        span:first-child {
            transform: translateY(7px) rotate(45deg);
        }
        span:nth-child(2) {
            transform: rotateY(90deg);
        }
        span:last-child {
            transform: translateY(-7px) rotate(-45deg);
        }
    }

    @media (min-width: 640px) {
        right: calc((100vw - 600px) / 2);
    }
    @media (min-width: 768px) {
        right: calc((100vw - 740px) / 2);
    }
    @media (min-width: 1024px) {
        right: calc((100vw - 992px) / 2);
    }
    @media (min-width: 1280px) {
        right: calc((100vw - 1200px) / 2);
    }
    @media (min-width: 1920px) {
        right: 15vw;
    }
}

.mobile-company-logo {
    @media (min-width: 640px) {
        left: calc((100vw - 600px) / 2);
    }
    @media (min-width: 768px) {
        left: calc((100vw - 740px) / 2);
    }
    @media (min-width: 1024px) {
        left: calc((100vw - 992px) / 2);
    }
    @media (min-width: 1280px) {
        left: calc((100vw - 1200px) / 2);
    }
    @media (min-width: 1920px) {
        left: 15vw;
    }
}
</style>
