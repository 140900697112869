import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0021b33c = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _7dc08c0e = () => interopDefault(import('../pages/case-studies.vue' /* webpackChunkName: "pages/case-studies" */))
const _1d07134c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _3111b767 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _20f0f804 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4fceb52a = () => interopDefault(import('../pages/filipino-workforce.vue' /* webpackChunkName: "pages/filipino-workforce" */))
const _6aa680c8 = () => interopDefault(import('../pages/indian-workforce.vue' /* webpackChunkName: "pages/indian-workforce" */))
const _f8e62008 = () => interopDefault(import('../pages/job-seeker/index.vue' /* webpackChunkName: "pages/job-seeker/index" */))
const _6dbec11a = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _49de51ca = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _50d1a9e2 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _4c368f7e = () => interopDefault(import('../pages/job-seeker/advice/index.vue' /* webpackChunkName: "pages/job-seeker/advice/index" */))
const _753d106c = () => interopDefault(import('../pages/job-seeker/contact.vue' /* webpackChunkName: "pages/job-seeker/contact" */))
const _04e7ad9f = () => interopDefault(import('../pages/job-seeker/jobs/index.vue' /* webpackChunkName: "pages/job-seeker/jobs/index" */))
const _74b5d988 = () => interopDefault(import('../pages/job-seeker/advice/preview/_advice_preview.vue' /* webpackChunkName: "pages/job-seeker/advice/preview/_advice_preview" */))
const _5fb40b9e = () => interopDefault(import('../pages/job-seeker/jobs/preview/_job_preview.vue' /* webpackChunkName: "pages/job-seeker/jobs/preview/_job_preview" */))
const _3e747574 = () => interopDefault(import('../pages/job-seeker/advice/_advice.vue' /* webpackChunkName: "pages/job-seeker/advice/_advice" */))
const _514688c1 = () => interopDefault(import('../pages/job-seeker/jobs/_job.vue' /* webpackChunkName: "pages/job-seeker/jobs/_job" */))
const _6765490f = () => interopDefault(import('../pages/news/preview/_article_preview.vue' /* webpackChunkName: "pages/news/preview/_article_preview" */))
const _2432161f = () => interopDefault(import('../pages/news/_article.vue' /* webpackChunkName: "pages/news/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _0021b33c,
    name: "about-us___en___default"
  }, {
    path: "/case-studies",
    component: _7dc08c0e,
    name: "case-studies___en___default"
  }, {
    path: "/contact",
    component: _1d07134c,
    name: "contact___en___default"
  }, {
    path: "/cookie-policy",
    component: _3111b767,
    name: "cookie-policy___en___default"
  }, {
    path: "/de",
    component: _20f0f804,
    name: "index___de"
  }, {
    path: "/en",
    component: _20f0f804,
    name: "index___en"
  }, {
    path: "/filipino-workforce",
    component: _4fceb52a,
    name: "filipino-workforce___en___default"
  }, {
    path: "/hu",
    component: _20f0f804,
    name: "index___hu"
  }, {
    path: "/indian-workforce",
    component: _6aa680c8,
    name: "indian-workforce___en___default"
  }, {
    path: "/job-seeker",
    component: _f8e62008,
    name: "job-seeker___en___default"
  }, {
    path: "/ko",
    component: _20f0f804,
    name: "index___ko"
  }, {
    path: "/news",
    component: _6dbec11a,
    name: "news___en___default"
  }, {
    path: "/privacy-policy",
    component: _49de51ca,
    name: "privacy-policy___en___default"
  }, {
    path: "/services",
    component: _50d1a9e2,
    name: "services___en___default"
  }, {
    path: "/zh",
    component: _20f0f804,
    name: "index___zh"
  }, {
    path: "/de/about-us",
    component: _0021b33c,
    name: "about-us___de"
  }, {
    path: "/de/case-studies",
    component: _7dc08c0e,
    name: "case-studies___de"
  }, {
    path: "/de/contact",
    component: _1d07134c,
    name: "contact___de"
  }, {
    path: "/de/cookie-policy",
    component: _3111b767,
    name: "cookie-policy___de"
  }, {
    path: "/de/filipino-workforce",
    component: _4fceb52a,
    name: "filipino-workforce___de"
  }, {
    path: "/de/indian-workforce",
    component: _6aa680c8,
    name: "indian-workforce___de"
  }, {
    path: "/de/job-seeker",
    component: _f8e62008,
    name: "job-seeker___de"
  }, {
    path: "/de/news",
    component: _6dbec11a,
    name: "news___de"
  }, {
    path: "/de/privacy-policy",
    component: _49de51ca,
    name: "privacy-policy___de"
  }, {
    path: "/de/services",
    component: _50d1a9e2,
    name: "services___de"
  }, {
    path: "/en/about-us",
    component: _0021b33c,
    name: "about-us___en"
  }, {
    path: "/en/case-studies",
    component: _7dc08c0e,
    name: "case-studies___en"
  }, {
    path: "/en/contact",
    component: _1d07134c,
    name: "contact___en"
  }, {
    path: "/en/cookie-policy",
    component: _3111b767,
    name: "cookie-policy___en"
  }, {
    path: "/en/filipino-workforce",
    component: _4fceb52a,
    name: "filipino-workforce___en"
  }, {
    path: "/en/indian-workforce",
    component: _6aa680c8,
    name: "indian-workforce___en"
  }, {
    path: "/en/job-seeker",
    component: _f8e62008,
    name: "job-seeker___en"
  }, {
    path: "/en/news",
    component: _6dbec11a,
    name: "news___en"
  }, {
    path: "/en/privacy-policy",
    component: _49de51ca,
    name: "privacy-policy___en"
  }, {
    path: "/en/services",
    component: _50d1a9e2,
    name: "services___en"
  }, {
    path: "/hu/about-us",
    component: _0021b33c,
    name: "about-us___hu"
  }, {
    path: "/hu/case-studies",
    component: _7dc08c0e,
    name: "case-studies___hu"
  }, {
    path: "/hu/contact",
    component: _1d07134c,
    name: "contact___hu"
  }, {
    path: "/hu/cookie-policy",
    component: _3111b767,
    name: "cookie-policy___hu"
  }, {
    path: "/hu/filipino-workforce",
    component: _4fceb52a,
    name: "filipino-workforce___hu"
  }, {
    path: "/hu/indian-workforce",
    component: _6aa680c8,
    name: "indian-workforce___hu"
  }, {
    path: "/hu/job-seeker",
    component: _f8e62008,
    name: "job-seeker___hu"
  }, {
    path: "/hu/news",
    component: _6dbec11a,
    name: "news___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _49de51ca,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/services",
    component: _50d1a9e2,
    name: "services___hu"
  }, {
    path: "/job-seeker/advice",
    component: _4c368f7e,
    name: "job-seeker-advice___en___default"
  }, {
    path: "/job-seeker/contact",
    component: _753d106c,
    name: "job-seeker-contact___en___default"
  }, {
    path: "/job-seeker/jobs",
    component: _04e7ad9f,
    name: "job-seeker-jobs___en___default"
  }, {
    path: "/ko/about-us",
    component: _0021b33c,
    name: "about-us___ko"
  }, {
    path: "/ko/case-studies",
    component: _7dc08c0e,
    name: "case-studies___ko"
  }, {
    path: "/ko/contact",
    component: _1d07134c,
    name: "contact___ko"
  }, {
    path: "/ko/cookie-policy",
    component: _3111b767,
    name: "cookie-policy___ko"
  }, {
    path: "/ko/filipino-workforce",
    component: _4fceb52a,
    name: "filipino-workforce___ko"
  }, {
    path: "/ko/indian-workforce",
    component: _6aa680c8,
    name: "indian-workforce___ko"
  }, {
    path: "/ko/job-seeker",
    component: _f8e62008,
    name: "job-seeker___ko"
  }, {
    path: "/ko/news",
    component: _6dbec11a,
    name: "news___ko"
  }, {
    path: "/ko/privacy-policy",
    component: _49de51ca,
    name: "privacy-policy___ko"
  }, {
    path: "/ko/services",
    component: _50d1a9e2,
    name: "services___ko"
  }, {
    path: "/zh/about-us",
    component: _0021b33c,
    name: "about-us___zh"
  }, {
    path: "/zh/case-studies",
    component: _7dc08c0e,
    name: "case-studies___zh"
  }, {
    path: "/zh/contact",
    component: _1d07134c,
    name: "contact___zh"
  }, {
    path: "/zh/cookie-policy",
    component: _3111b767,
    name: "cookie-policy___zh"
  }, {
    path: "/zh/filipino-workforce",
    component: _4fceb52a,
    name: "filipino-workforce___zh"
  }, {
    path: "/zh/indian-workforce",
    component: _6aa680c8,
    name: "indian-workforce___zh"
  }, {
    path: "/zh/job-seeker",
    component: _f8e62008,
    name: "job-seeker___zh"
  }, {
    path: "/zh/news",
    component: _6dbec11a,
    name: "news___zh"
  }, {
    path: "/zh/privacy-policy",
    component: _49de51ca,
    name: "privacy-policy___zh"
  }, {
    path: "/zh/services",
    component: _50d1a9e2,
    name: "services___zh"
  }, {
    path: "/de/job-seeker/advice",
    component: _4c368f7e,
    name: "job-seeker-advice___de"
  }, {
    path: "/de/job-seeker/contact",
    component: _753d106c,
    name: "job-seeker-contact___de"
  }, {
    path: "/de/job-seeker/jobs",
    component: _04e7ad9f,
    name: "job-seeker-jobs___de"
  }, {
    path: "/en/job-seeker/advice",
    component: _4c368f7e,
    name: "job-seeker-advice___en"
  }, {
    path: "/en/job-seeker/contact",
    component: _753d106c,
    name: "job-seeker-contact___en"
  }, {
    path: "/en/job-seeker/jobs",
    component: _04e7ad9f,
    name: "job-seeker-jobs___en"
  }, {
    path: "/hu/job-seeker/advice",
    component: _4c368f7e,
    name: "job-seeker-advice___hu"
  }, {
    path: "/hu/job-seeker/contact",
    component: _753d106c,
    name: "job-seeker-contact___hu"
  }, {
    path: "/hu/job-seeker/jobs",
    component: _04e7ad9f,
    name: "job-seeker-jobs___hu"
  }, {
    path: "/ko/job-seeker/advice",
    component: _4c368f7e,
    name: "job-seeker-advice___ko"
  }, {
    path: "/ko/job-seeker/contact",
    component: _753d106c,
    name: "job-seeker-contact___ko"
  }, {
    path: "/ko/job-seeker/jobs",
    component: _04e7ad9f,
    name: "job-seeker-jobs___ko"
  }, {
    path: "/zh/job-seeker/advice",
    component: _4c368f7e,
    name: "job-seeker-advice___zh"
  }, {
    path: "/zh/job-seeker/contact",
    component: _753d106c,
    name: "job-seeker-contact___zh"
  }, {
    path: "/zh/job-seeker/jobs",
    component: _04e7ad9f,
    name: "job-seeker-jobs___zh"
  }, {
    path: "/",
    component: _20f0f804,
    name: "index___en___default"
  }, {
    path: "/de/job-seeker/advice/preview/:advice_preview",
    component: _74b5d988,
    name: "job-seeker-advice-preview-advice_preview___de"
  }, {
    path: "/de/job-seeker/jobs/preview/:job_preview",
    component: _5fb40b9e,
    name: "job-seeker-jobs-preview-job_preview___de"
  }, {
    path: "/en/job-seeker/advice/preview/:advice_preview",
    component: _74b5d988,
    name: "job-seeker-advice-preview-advice_preview___en"
  }, {
    path: "/en/job-seeker/jobs/preview/:job_preview",
    component: _5fb40b9e,
    name: "job-seeker-jobs-preview-job_preview___en"
  }, {
    path: "/hu/job-seeker/advice/preview/:advice_preview",
    component: _74b5d988,
    name: "job-seeker-advice-preview-advice_preview___hu"
  }, {
    path: "/hu/job-seeker/jobs/preview/:job_preview",
    component: _5fb40b9e,
    name: "job-seeker-jobs-preview-job_preview___hu"
  }, {
    path: "/ko/job-seeker/advice/preview/:advice_preview",
    component: _74b5d988,
    name: "job-seeker-advice-preview-advice_preview___ko"
  }, {
    path: "/ko/job-seeker/jobs/preview/:job_preview",
    component: _5fb40b9e,
    name: "job-seeker-jobs-preview-job_preview___ko"
  }, {
    path: "/zh/job-seeker/advice/preview/:advice_preview",
    component: _74b5d988,
    name: "job-seeker-advice-preview-advice_preview___zh"
  }, {
    path: "/zh/job-seeker/jobs/preview/:job_preview",
    component: _5fb40b9e,
    name: "job-seeker-jobs-preview-job_preview___zh"
  }, {
    path: "/de/job-seeker/advice/:advice",
    component: _3e747574,
    name: "job-seeker-advice-advice___de"
  }, {
    path: "/de/job-seeker/jobs/:job",
    component: _514688c1,
    name: "job-seeker-jobs-job___de"
  }, {
    path: "/de/news/preview/:article_preview?",
    component: _6765490f,
    name: "news-preview-article_preview___de"
  }, {
    path: "/en/job-seeker/advice/:advice",
    component: _3e747574,
    name: "job-seeker-advice-advice___en"
  }, {
    path: "/en/job-seeker/jobs/:job",
    component: _514688c1,
    name: "job-seeker-jobs-job___en"
  }, {
    path: "/en/news/preview/:article_preview?",
    component: _6765490f,
    name: "news-preview-article_preview___en"
  }, {
    path: "/hu/job-seeker/advice/:advice",
    component: _3e747574,
    name: "job-seeker-advice-advice___hu"
  }, {
    path: "/hu/job-seeker/jobs/:job",
    component: _514688c1,
    name: "job-seeker-jobs-job___hu"
  }, {
    path: "/hu/news/preview/:article_preview?",
    component: _6765490f,
    name: "news-preview-article_preview___hu"
  }, {
    path: "/job-seeker/advice/preview/:advice_preview",
    component: _74b5d988,
    name: "job-seeker-advice-preview-advice_preview___en___default"
  }, {
    path: "/job-seeker/jobs/preview/:job_preview",
    component: _5fb40b9e,
    name: "job-seeker-jobs-preview-job_preview___en___default"
  }, {
    path: "/ko/job-seeker/advice/:advice",
    component: _3e747574,
    name: "job-seeker-advice-advice___ko"
  }, {
    path: "/ko/job-seeker/jobs/:job",
    component: _514688c1,
    name: "job-seeker-jobs-job___ko"
  }, {
    path: "/ko/news/preview/:article_preview?",
    component: _6765490f,
    name: "news-preview-article_preview___ko"
  }, {
    path: "/zh/job-seeker/advice/:advice",
    component: _3e747574,
    name: "job-seeker-advice-advice___zh"
  }, {
    path: "/zh/job-seeker/jobs/:job",
    component: _514688c1,
    name: "job-seeker-jobs-job___zh"
  }, {
    path: "/zh/news/preview/:article_preview?",
    component: _6765490f,
    name: "news-preview-article_preview___zh"
  }, {
    path: "/de/news/:article",
    component: _2432161f,
    name: "news-article___de"
  }, {
    path: "/en/news/:article",
    component: _2432161f,
    name: "news-article___en"
  }, {
    path: "/hu/news/:article",
    component: _2432161f,
    name: "news-article___hu"
  }, {
    path: "/job-seeker/advice/:advice",
    component: _3e747574,
    name: "job-seeker-advice-advice___en___default"
  }, {
    path: "/job-seeker/jobs/:job",
    component: _514688c1,
    name: "job-seeker-jobs-job___en___default"
  }, {
    path: "/ko/news/:article",
    component: _2432161f,
    name: "news-article___ko"
  }, {
    path: "/news/preview/:article_preview?",
    component: _6765490f,
    name: "news-preview-article_preview___en___default"
  }, {
    path: "/zh/news/:article",
    component: _2432161f,
    name: "news-article___zh"
  }, {
    path: "/news/:article",
    component: _2432161f,
    name: "news-article___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
