<template>
    <a
        :href="url"
        target="_blank"
        rel="noopener noreferrer"
        :class="
            isLarge
                ? 'p-component p-button p-button-secondary p-button-outlined p-button-icon p-button-icon-only'
                : 'text-white transition-colors desktop:hover:text-[#ED5D3E]'
        "
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M11.2335 0H4.76649C2.13823 0 0 2.13823 0 4.76649V11.2335C0 13.8618 2.13823 16 4.76649 16H11.2335C13.8618 16 16 13.8618 16 11.2335V4.76649C16 2.13823 13.8617 0 11.2335 0ZM14.3904 11.2335C14.3904 12.977 12.977 14.3904 11.2335 14.3904H4.76649C3.023 14.3904 1.6096 12.977 1.6096 11.2335V4.76649C1.6096 3.02297 3.023 1.6096 4.76649 1.6096H11.2335C12.977 1.6096 14.3904 3.02297 14.3904 4.76649V11.2335Z"
                class="fill-current"
            />
            <path
                d="M7.9995 3.86182C5.71771 3.86182 3.86133 5.7182 3.86133 7.99996C3.86133 10.2817 5.71771 12.1381 7.9995 12.1381C10.2813 12.1381 12.1377 10.2817 12.1377 7.99996C12.1377 5.71817 10.2813 3.86182 7.9995 3.86182ZM7.9995 10.5286C6.603 10.5286 5.47093 9.39649 5.47093 7.99999C5.47093 6.60348 6.60302 5.47142 7.9995 5.47142C9.396 5.47142 10.5281 6.60348 10.5281 7.99999C10.5281 9.39646 9.39597 10.5286 7.9995 10.5286Z"
                class="fill-current"
            />
            <path
                d="M12.1459 4.88449C12.6935 4.88449 13.1374 4.44055 13.1374 3.89293C13.1374 3.3453 12.6935 2.90137 12.1459 2.90137C11.5982 2.90137 11.1543 3.3453 11.1543 3.89293C11.1543 4.44055 11.5982 4.88449 12.1459 4.88449Z"
                class="fill-current"
            />
        </svg>
    </a>
</template>

<script>
export default {
    name: 'Linkedin',
    props: {
        isLarge: {
            type: Boolean,
            required: false,
            default: false,
        },
        url: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
