export const state = () => ({
    scrollY: 0,
    // if a page exists in a different language too, but has a different slug,
    // then the url after the locale update event will get parsed from here
    alternateUrlsForCurrentPage: [],
});

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setAlternateUrlsForCurrentPage(state, payload) {
        state.alternateUrlsForCurrentPage = payload || [];
    },
};
