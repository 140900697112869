export const state = () => ({
    isMobileFilterPanelOpen: false,
    isMobileFilterPanelOverlappingHeader: false,
    isMobileFilterCTAContainerVisible: true,
    lastSearchQuery: null,
});

export const mutations = {
    setIsMobileFilterPanelOpen(state, payload) {
        state.isMobileFilterPanelOpen = payload;

        if (payload === true) {
            state.isMobileFilterPanelOverlappingHeader = true;
        } else {
            setTimeout(() => {
                if (!state.isMobileFilterPanelOpen) {
                    this.commit('jobs/setisMobileFilterPanelOverlappingHeader', false);
                }
            }, 300);
        }
    },
    setisMobileFilterPanelOverlappingHeader(state, payload) {
        state.isMobileFilterPanelOverlappingHeader = payload;
    },
    setIsMobileFilterCTAContainerVisible(state, payload) {
        state.isMobileFilterCTAContainerVisible = payload;
    },
    setLastSearchQuery(state, payload) {
        state.lastSearchQuery = payload;
    },
};
