<template>
    <div class="relative purple-gradient-bg rounded-br-200">
        <div
            class="absolute w-full h-full top-0 left-0 bg-cover bg-no-repeat bg-center"
            :style="`background-image: url(${require('~/assets/images/illustrations/logo-as-hero-bg-shape.svg')})`"
        ></div>
        <div class="relative container py-24 sm:py-40 lg:py-72 z-10">
            <div class="grid grid-cols-12 gap-5">
                <div class="col-span-12 sm:col-span-5 sm:col-start-2">
                    <div class="status-code text-[8rem] lg:text-[15rem] xl:text-[22rem]">{{ error.statusCode }}</div>
                </div>
                <div class="col-span-12 sm:col-span-5 sm:col-start-8 flex flex-col justify-center items-start">
                    <p class="font-Abhaya-Libre font-bold text-3xl mb-8">{{ error.message }}</p>
                    <p v-if="error.statusCode === 404" class="mb-8">
                        {{ $t('error.page_not_found.description') }}
                    </p>

                    <NuxtLink
                        :to="localePath(backButtonUrl)"
                        class="p-button p-button-secondary p-button-light p-button-text"
                    >
                        <span class="pi pi-arrow-left p-button-icon p-button-icon-left"></span>
                        <span class="p-button-label">{{ $t('error.back_to_homepage') }}</span>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    layout: 'Default',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    computed: {
        backButtonUrl() {
            if (this.$route.path.match(/^(\/[a-z]{2})?\/job-seeker/)) {
                return '/job-seeker';
            }
            return '/';
        },
    },
};
</script>

<style scoped>
.status-code {
    @apply font-Abhaya-Libre font-bold;
}
</style>
