export default function ({ i18n, $recaptcha, $axios, $config, $dayjs }) {
    // i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
    //     console.log(oldLocale, newLocale, isInitialSetup)
    // }

    $recaptcha.language = i18n.locale;

    i18n.onLanguageSwitched = (oldLocale, newLocale) => {
        $axios.defaults.baseURL = $config.baseURL + 'api/' + newLocale;
        $dayjs.locale(newLocale);
        $recaptcha.language = newLocale;
    };
}
