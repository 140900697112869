export default ({ app, $config, $device, i18n, route, req }) => {
    // Get host from proxy header
    let host;

    if (process.server) {
        host = req.headers['x-forwarded-host'];
    } else {
        host = window.location.origin;
    }

    if ($config.nodeENV !== 'development' || !/^https/.test(host)) {
        host = 'https://' + host;
    }

    // Add scripts
    const script = [];

    if ($config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host) && !$device.isCrawler) {
        script.push({
            src: '/scripts/meta-pixel.js',
            async: true,
        });
    }

    app.head.script.push(...script);

    // Add meta tags
    const meta = [];

    meta.push({
        property: 'og:url',
        content: host + route.fullPath,
    });

    meta.push({
        hid: 'title',
        name: 'title',
        content: 'Bányai & Partners Consulting',
    });

    meta.push({
        hid: 'og:title',
        property: 'og:title',
        content: 'Bányai & Partners Consulting',
    });

    meta.push({
        hid: 'description',
        name: 'description',
        content: i18n.t('homepage.hero.lead'),
    });

    meta.push({
        hid: 'og:description',
        property: 'og:description',
        content: i18n.t('homepage.hero.lead'),
    });

    meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: `${host}/og-image.jpg`,
    });

    meta.push({
        hid: 'og:image:url',
        property: 'og:image:url',
        content: `${host}/og-image.jpg`,
    });

    meta.push({
        property: 'og:image:type',
        content: 'image/jpg',
    });

    meta.push({
        name: 'robots',
        content: $config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host) ? 'all' : 'noindex, nofollow',
    });

    app.head.meta.push(...meta);
};
