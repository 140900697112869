export const state = () => ({
    windowWidth: 0,
});

export const getters = {
    getWindowWidth(state) {
        return state.windowWidth;
    },
};

export const mutations = {
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
};
