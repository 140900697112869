<template>
    <svg width="64" height="52" viewBox="0 0 64 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M31.8162 17.5641C36.6664 17.5641 40.5983 13.6323 40.5983 8.78206C40.5983 3.93186 36.6664 0 31.8162 0C26.966 0 23.0342 3.93186 23.0342 8.78206C23.0342 13.6323 26.966 17.5641 31.8162 17.5641Z"
            :fill="monochrome ? '#FFF' : '#2EB9EB'"
        />
        <path
            d="M0 20.007C14.91 21.9666 26.4208 34.7219 26.4208 50.1668C26.4208 50.5112 26.4128 50.8556 26.4013 51.2H37.0844C37.0921 50.8564 37.0963 50.512 37.097 50.1668C37.097 29.4147 21.7141 12.2547 1.7323 9.46625L0 20.007Z"
            :fill="monochrome ? '#FFF' : '#2EB9EB'"
        />
        <path
            d="M63.5008 20.007C48.5909 21.9666 37.08 34.7219 37.08 50.1668C37.08 50.5112 37.0881 50.8556 37.0996 51.2H26.4164C26.408 50.8564 26.4038 50.512 26.4038 50.1668C26.4038 29.4147 41.7868 12.2547 61.7685 9.46625L63.5008 20.007Z"
            :fill="monochrome ? '#FFF' : '#E73A92'"
        />
        <path
            d="M37.08 50.1668C37.08 49.9212 37.0831 49.6759 37.0892 49.431C36.9773 42.571 35.1412 35.8495 31.7499 29.8854C28.3595 35.8498 26.5238 42.5712 26.4118 49.431C26.4172 49.6759 26.4202 49.9212 26.421 50.1668C26.421 50.4033 26.421 50.6398 26.4106 50.8751C26.4106 50.9899 26.4106 51.0921 26.4106 51.2H37.0788C37.0788 51.0921 37.0788 50.9842 37.0788 50.8751C37.0846 50.6398 37.08 50.4033 37.08 50.1668Z"
            :fill="monochrome ? '#FFF' : '#2A2A88'"
        />
    </svg>
</template>

<script>
export default {
    name: 'SiteLogo',
    props: {
        monochrome: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
path {
    transition: all 0.2s ease;
    &:nth-of-type(1) {
        transition-delay: 0.05s;
    }
    &:nth-of-type(3) {
        transition-delay: 0.15s;
    }
    &:nth-of-type(4) {
        transition-delay: 0.1s;
    }
}
</style>
