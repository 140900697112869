<template>
    <NuxtLink
        :to="localePath('/job-seeker/jobs')"
        class="
            p-component p-button p-button-secondary p-button-light p-button-outlined
            mr-4
            py-2
            opacity-0
            transition-all
        "
        :class="{
            'pl-4 pr-2': !$store.state.cookie.jobOffersLastCheckedDate || newJobOffersCount, // basically if the job counter badge is there
            'opacity-100': dataLoaded,
        }"
    >
        <span class="p-button-label mr-1 capital-initial">
            {{ $t('jobs.title') }}
        </span>
        <span
            v-if="!$store.state.cookie.jobOffersLastCheckedDate && newJobOffersCount"
            class="bg-white/10 rounded-full p-2 pr-3 inline-flex items-center uppercase"
        >
            <span
                class="
                    relative
                    inline-flex
                    items-center
                    justify-center
                    w-6
                    h-6
                    rounded-full
                    bg-magenta-400
                    mr-2
                    bg-center bg-no-repeat
                "
                :style="`background-size: 14px 16px; background-image: url(${require('assets/images/icons/bell.svg')})`"
            >
            </span>
            <span class="text-xs">
                {{ newJobOffersCount ? newJobOffersCount : totalJobOffersCount }}
                {{ newJobOffersCount ? $t('generic.new') : '' }} {{ $tc('jobs.offer', newJobOffersCount) }}
            </span>
        </span>
        <span v-else-if="totalJobOffersCount">({{ totalJobOffersCount }})</span>
    </NuxtLink>
</template>

<script>
export default {
    name: 'JobOffersNotificationButton',
    data() {
        return {
            dataLoaded: false,
            totalJobOffersCount: null,
            newJobOffersCount: null,
        };
    },
    watch: {
        '$store.state.cookie.jobOffersLastCheckedDate'() {
            this.getNewJobOffersCountSince(this.$store.state.cookie.jobOffersLastCheckedDate || '1970-01-01');
        },
        '$i18n.locale'() {
            this.getNewJobOffersCountSince(this.$store.state.cookie.jobOffersLastCheckedDate || '1970-01-01');
        },
    },
    mounted() {
        this.getNewJobOffersCountSince(this.$store.state.cookie.jobOffersLastCheckedDate || '1970-01-01');
    },
    methods: {
        async getNewJobOffersCountSince(date) {
            if (!date) {
                return null;
            }
            await this.$axios
                .$get(`/check?date=${date}`)
                .then((res) => {
                    if (res.success === 1) {
                        this.dataLoaded = true;
                        this.newJobOffersCount = res.data.new_jobs;
                        this.totalJobOffersCount = res.data.total_jobs;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped></style>
