<template>
    <a
        :href="url"
        target="_blank"
        rel="noopener noreferrer"
        :class="[
            isLarge
                ? 'p-component p-button p-button-secondary p-button-outlined p-button-icon p-button-icon-only'
                : 'text-white transition-colors desktop:hover:text-[#0077B5]',
            isShareButton ? 'p-button-share' : '',
        ]"
    >
        <svg
            v-if="isShareButton"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M4.65548 6.4375H0.640452C0.462258 6.4375 0.317871 6.58195 0.317871 6.76008V19.6586C0.317871 19.8368 0.462258 19.9812 0.640452 19.9812H4.65548C4.83368 19.9812 4.97806 19.8368 4.97806 19.6586V6.76008C4.97806 6.58195 4.83368 6.4375 4.65548 6.4375Z"
                fill="black"
            />
            <path
                d="M2.64942 0.0234375C1.18852 0.0234375 0 1.21066 0 2.66996C0 4.12989 1.18852 5.31757 2.64942 5.31757C4.10916 5.31757 5.29671 4.12983 5.29671 2.66996C5.29677 1.21066 4.10916 0.0234375 2.64942 0.0234375Z"
                fill="black"
            />
            <path
                d="M14.868 6.11328C13.2555 6.11328 12.0634 6.80651 11.3404 7.59418V6.75644C11.3404 6.57831 11.196 6.43386 11.0178 6.43386H7.17268C6.99448 6.43386 6.8501 6.57831 6.8501 6.75644V19.655C6.8501 19.8332 6.99448 19.9776 7.17268 19.9776H11.1789C11.3571 19.9776 11.5015 19.8332 11.5015 19.655V13.2732C11.5015 11.1227 12.0856 10.2849 13.5847 10.2849C15.2174 10.2849 15.3471 11.628 15.3471 13.3839V19.6551C15.3471 19.8333 15.4915 19.9777 15.6697 19.9777H19.6775C19.8556 19.9777 20 19.8333 20 19.6551V12.58C20 9.38225 19.3903 6.11328 14.868 6.11328Z"
                fill="black"
            />
        </svg>

        <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M3.724 5.14722H0.511971C0.369416 5.14722 0.253906 5.26278 0.253906 5.40528V15.7241C0.253906 15.8667 0.369416 15.9822 0.511971 15.9822H3.724C3.86655 15.9822 3.98206 15.8667 3.98206 15.7241V5.40528C3.98206 5.26278 3.86655 5.14722 3.724 5.14722Z"
                class="fill-current"
            />
            <path
                d="M2.11954 0.0175781C0.950813 0.0175781 0 0.967359 0 2.13479C0 3.30274 0.950813 4.25288 2.11954 4.25288C3.28733 4.25288 4.23737 3.30269 4.23737 2.13479C4.23742 0.967359 3.28733 0.0175781 2.11954 0.0175781Z"
                class="fill-current"
            />
            <path
                d="M11.8948 4.89087C10.6048 4.89087 9.6511 5.44545 9.07267 6.07559V5.4054C9.07267 5.26289 8.95716 5.14733 8.81461 5.14733H5.73853C5.59598 5.14733 5.48047 5.26289 5.48047 5.4054V15.7243C5.48047 15.8668 5.59598 15.9823 5.73853 15.9823H8.94354C9.08609 15.9823 9.2016 15.8668 9.2016 15.7243V10.6188C9.2016 8.8984 9.66891 8.22816 10.8682 8.22816C12.1743 8.22816 12.2781 9.30264 12.2781 10.7073V15.7243C12.2781 15.8669 12.3936 15.9824 12.5362 15.9824H15.7424C15.8849 15.9824 16.0004 15.8669 16.0004 15.7243V10.0642C16.0004 7.50604 15.5126 4.89087 11.8948 4.89087Z"
                class="fill-current"
            />
        </svg>
    </a>
</template>

<script>
export default {
    name: 'Linkedin',
    props: {
        url: {
            type: String,
            required: true,
        },
        isLarge: {
            type: Boolean,
            required: false,
            default: false,
        },
        isShareButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
