<template>
    <div class="flex">
        <div
            class="w-14 h-14 min-w-14 min-h-14 bg-no-repeat bg-center rounded-lg mr-4"
            :class="blue ? 'bg-blue-700' : 'bg-gray-500/10'"
            :style="`background-image: url(${icon})`"
        ></div>
        <div class="text-left" :class="textBlack && 'text-black'">
            <span class="block font-bold">{{ label }}</span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactDetail',
    props: {
        icon: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        blue: {
            type: Boolean,
            required: false,
            default: false,
        },
        textBlack: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
