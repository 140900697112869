<template>
    <div id="__layout__content" class="flex flex-col min-h-screen">
        <Header class="z-40" />

        <Nuxt class="relative flex-grow z-0" />

        <Footer class="relative z-5" />

        <CookieBar />

        <GridOverlay />
    </div>
</template>

<script>
import Header from '@/components/Layout/Header';
import Footer from '@/components/Layout/Footer';
import GridOverlay from '@/components/Layout/GridOverlay';
import CookieBar from '@/components/Generic/CookieBar';

import i18n from '@/mixins/i18n';

export default {
    name: 'Default',
    components: {
        Header,
        Footer,
        GridOverlay,
        CookieBar,
    },
    mixins: [i18n],
};
</script>

<style scoped></style>
