<template>
    <a
        :href="url"
        target="_blank"
        rel="noopener noreferrer"
        :class="[
            isLarge
                ? 'p-component p-button p-button-secondary p-button-outlined p-button-icon p-button-icon-only'
                : 'text-white transition-colors desktop:hover:text-[#1DA1F2]',
            isShareButton ? 'p-button-share' : '',
        ]"
    >
        <svg
            v-if="isShareButton"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
        >
            <path
                d="M19.5468 2.70224C19.2326 2.8416 18.9095 2.95921 18.5795 3.05469C18.9703 2.61276 19.2682 2.09276 19.4501 1.52373C19.4908 1.39618 19.4486 1.25656 19.3437 1.17315C19.239 1.08966 19.0935 1.07966 18.9783 1.14798C18.2773 1.56373 17.521 1.8625 16.7281 2.03721C15.9293 1.25669 14.8444 0.8125 13.7228 0.8125C11.3554 0.8125 9.42936 2.7385 9.42936 5.10585C9.42936 5.29231 9.44117 5.47773 9.46452 5.66056C6.52678 5.40263 3.79562 3.95869 1.92014 1.65811C1.8533 1.57611 1.75033 1.53192 1.64492 1.54037C1.53943 1.54863 1.44466 1.60811 1.39137 1.69953C1.01098 2.35224 0.809884 3.09876 0.809884 3.85831C0.809884 4.89282 1.17924 5.87437 1.83169 6.64134C1.6333 6.57263 1.44079 6.48676 1.25704 6.38476C1.1584 6.32986 1.03801 6.33069 0.940013 6.38689C0.841948 6.44308 0.780465 6.54644 0.777884 6.6594C0.777432 6.67844 0.777432 6.69747 0.777432 6.71676C0.777432 8.26095 1.60853 9.65121 2.87917 10.409C2.77001 10.398 2.66091 10.3822 2.55253 10.3615C2.44079 10.3402 2.32588 10.3793 2.25053 10.4646C2.17504 10.5497 2.15008 10.6684 2.18485 10.7768C2.65517 12.2452 3.86607 13.3253 5.32994 13.6546C4.11582 14.415 2.72737 14.8133 1.26982 14.8133C0.96569 14.8133 0.659819 14.7955 0.360465 14.76C0.211755 14.7424 0.069562 14.8302 0.0189169 14.9716C-0.0317282 15.1131 0.0219491 15.2708 0.148465 15.3519C2.02092 16.5525 4.18601 17.1871 6.40956 17.1871C10.7808 17.1871 13.5153 15.1258 15.0395 13.3966C16.9401 11.2404 18.0302 8.38637 18.0302 5.56644C18.0302 5.44863 18.0284 5.32966 18.0248 5.21108C18.7746 4.64611 19.4203 3.96237 19.9456 3.1765C20.0254 3.05715 20.0168 2.8994 19.9243 2.78953C19.832 2.6796 19.6781 2.64405 19.5468 2.70224Z"
                fill="black"
            />
        </svg>

        <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_181_11232)">
                <path
                    d="M15.6374 2.96199C15.3861 3.07347 15.1276 3.16756 14.8636 3.24395C15.1762 2.8904 15.4145 2.4744 15.56 2.01918C15.5927 1.91714 15.5589 1.80545 15.475 1.73871C15.3912 1.67192 15.2748 1.66392 15.1826 1.71858C14.6218 2.05118 14.0168 2.2902 13.3824 2.42996C12.7434 1.80555 11.8755 1.4502 10.9783 1.4502C9.08434 1.4502 7.54349 2.991 7.54349 4.88488C7.54349 5.03404 7.55293 5.18238 7.57162 5.32865C5.22143 5.1223 3.0365 3.96715 1.53611 2.12669C1.48264 2.06109 1.40027 2.02573 1.31593 2.03249C1.23155 2.0391 1.15573 2.08669 1.11309 2.15982C0.808784 2.68199 0.647907 3.2792 0.647907 3.88684C0.647907 4.71445 0.943391 5.49969 1.46535 6.11327C1.30664 6.0583 1.15263 5.9896 1.00564 5.908C0.92672 5.86408 0.83041 5.86475 0.75201 5.90971C0.673559 5.95466 0.624372 6.03734 0.622307 6.12772C0.621946 6.14294 0.621946 6.15817 0.621946 6.1736C0.621946 7.40896 1.28682 8.52116 2.30334 9.12736C2.21601 9.11863 2.12873 9.10599 2.04202 9.08942C1.95263 9.07234 1.86071 9.10367 1.80042 9.17185C1.74004 9.23998 1.72006 9.33494 1.74788 9.42165C2.12414 10.5964 3.09286 11.4604 4.26396 11.7238C3.29265 12.3322 2.18189 12.6509 1.01586 12.6509C0.772552 12.6509 0.527856 12.6366 0.288372 12.6082C0.169404 12.5941 0.0556496 12.6643 0.0151335 12.7775C-0.0253826 12.8907 0.0175593 13.0169 0.118772 13.0817C1.61673 14.0422 3.34881 14.5499 5.12765 14.5499C8.62462 14.5499 10.8122 12.9008 12.0316 11.5174C13.5521 9.79249 14.4242 7.50929 14.4242 5.25334C14.4242 5.1591 14.4227 5.06392 14.4198 4.96906C15.0197 4.51709 15.5362 3.97009 15.9565 3.3414C16.0203 3.24591 16.0134 3.11972 15.9395 3.03182C15.8656 2.94387 15.7425 2.91543 15.6374 2.96199Z"
                    class="fill-current"
                />
            </g>
            <defs>
                <clipPath id="clip0_181_11232">
                    <rect width="16" height="16" class="fill-current" />
                </clipPath>
            </defs>
        </svg>
    </a>
</template>

<script>
export default {
    name: 'Linkedin',
    props: {
        url: {
            type: String,
            required: true,
        },
        isLarge: {
            type: Boolean,
            required: false,
            default: false,
        },
        isShareButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
