export default async function (to, from, savedPosition) {
    try {
        const findEl = (hash, x = 0) => {
            return (
                document.querySelector(hash) ||
                new Promise((resolve) => {
                    if (x > 50) {
                        return resolve(null);
                        // return resolve(document.querySelector('#__nuxt'));
                    }
                    setTimeout(() => {
                        resolve(findEl(hash, ++x || 1));
                    }, 100);
                })
            );
        };

        if (to.hash) {
            const $el = await findEl(to.hash);

            const computedTop = $el.offsetTop - 100;

            if ('scrollBehavior' in document.documentElement.style) {
                return { x: 0, y: computedTop, behavior: from.name === to.name ? 'smooth' : 'auto' };
            } else {
                return window.scrollTo(0, computedTop);
            }
        }

        if (savedPosition) {
            // this is going to be executed if the savedPosition holds a value,
            // aka you pressed the browser back/forward button
            return { x: 0, y: 0, behavior: 'auto' };
        }

        // this is executed in any scenario without route/hash change (e.g query change)
        if (from.path === to.path) {
            return { x: 0, y: window.scrollY, behavior: 'auto' };
        }

        // at last, this is executed on every route change, pointing to a url without hash
        // eslint-disable-next-line no-unreachable
        return { x: 0, y: 0, behavior: 'auto' };
    } catch (err) {
        console.log(err);
    }
}
