import { throttle } from 'throttle-debounce';

export default ({ store, $device, $cookies, $config }) => {
    const resizeEvent = throttle(200, false, () => {
        // $store.state.windowWidth is just here for convenience, so you don't have to write window.innerWidth
        // in a computed prop or something like that in the template every single time you need it
        store.commit('setWindowWidth', window.innerWidth);
    });

    const hasTouch = () => {
        return (
            'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
        );
    };

    const toggleGrid = (e) => {
        // To make development easier and your sitebuild more consistent with the design, there is a 12 col grid
        // you can turn on that appears over the layout. You can only enable it in development mode. Use it, it's free!
        if (e.ctrlKey && e.code === 'KeyM') {
            store.commit('dev/setIsGridOverlayVisible', !store.state.dev.isGridOverlayVisible);
        }
    };

    const toggleTranslationKeyVisibility = (e) => {
        // To help identify the translations on the site, you can make the translation keys appear next to the
        // translations themselves (most of the time, it only works for the translations that use the custom <T> component,
        // it does not work for translations that are inserted with i18n's $t() method)
        if (e.ctrlKey && e.altKey && e.code === 'KeyT') {
            $cookies.set('show_translation_keys', !$cookies.get('show_translation_keys'));
            location.reload();
        }
    };

    resizeEvent();

    window.addEventListener('resize', resizeEvent);

    window.document.body.onscroll = throttle(100, false, () => {
        // Dev scrollY is persisted and only used for dev purposes.
        // Global scrollY is not persisted and used for production.
        store.commit('globals/setScrollY', window.pageYOffset);
        if (process.env.NODE_ENV === 'development') {
            store.commit('dev/setScrollY', window.pageYOffset);
        }
    });

    if (process.env.NODE_ENV === 'development') {
        window.addEventListener('keyup', toggleGrid);
        // For easier development, on page refresh scrollY will be restored if the layout grid is turned on
        // (so you don't have to scroll down to the section you were working on every time the page reloads)
        if (store.state.dev.isGridOverlayVisible) {
            setTimeout(() => {
                window.scrollTo(0, store.state.dev.scrollY);
            }, 100);
        }
    }

    window.addEventListener('keyup', toggleTranslationKeyVisibility);

    // Add some helper classes for styling
    if ($device.isDesktop) {
        document.body.classList.add('desktop');
    }

    if ($device.isMobileOrTablet) {
        document.body.classList.add('mobile-tablet');
    }

    if ($device.isSafari) {
        document.body.classList.add('safari');
    }

    // This will erase all hover css rules on touch devices.
    // Note that if you have styles that apply for both a hovered element and a regular one,
    // e.g. .list-item, .list-item:hover {}, then the rule will be erased the same. For this reason,
    // it is recommended to not mix hover rules with non-hover ones.
    if (hasTouch()) {
        document.body.classList.add('touchscreen');

        try {
            // prevent exception on browsers not supporting DOM styleSheets properly
            for (const si in document.styleSheets) {
                const styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;

                for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    if (!styleSheet.rules[ri].selectorText) continue;

                    if (styleSheet.rules[ri].selectorText.match(':hover')) {
                        styleSheet.deleteRule(ri);
                    }
                }
            }
        } catch (ex) {}
    }
};
